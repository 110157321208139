export default function barraStatusReducer(state = [], action) {
  const AP = action.payload;

  switch (action.type) {
    case "PASSO1":
      return AP[0];

    case "PASSO2":
      return AP[0];

    case "INTPASSO":
      return AP[0];

    default:
      return state;
  }
}
