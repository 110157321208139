import React from "react";
import Home from "../../components/publico/Castro";
import Advogados from "../../components/publico/Advogados";
import Escritorio from "../../components/publico/Escritorio";
import Login from "../../../src/view/pages/login";
import Registro from "../../../src/view/pages/register";
import Forgot from "../../../src/view/pages/forgot";
import { useSelector } from "react-redux";

function ContainerPublic() {
  const navStatus = useSelector((state) => state.NavReducerPublic);

  if (navStatus === "HOME") {
    return (
      <div id="cont">
        <Home></Home>
      </div>
    );
  }

  if (navStatus === "LOGIN") {
    return (
      <div id="cont">
        <Login></Login>
      </div>
    );
  }

  if (navStatus === "CLIENTE") {
    return (
      <div id="cont">
        <Login></Login>
      </div>
    );
  }

  if (navStatus === "ADVOGADOS") {
    return (
      <div id="cont">
        <Advogados></Advogados>{" "}
      </div>
    );
  }

  if (navStatus === "ESCRITORIO") {
    return (
      <div id="cont">
        <Escritorio></Escritorio>
      </div>
    );
  }

  if (navStatus === "REGIS") {
    return (
      <div className="cont">
        <Registro></Registro>
      </div>
    );
  }

  if (navStatus === "FORGOT") {
    return (
      <div className="cont">
        <Forgot></Forgot>
      </div>
    );


  }



  return;
}

export default ContainerPublic;
