import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import DoneIcon from "@material-ui/icons/Done";
import "react-redux";
import "redux";
const Workplace = ({
  arrLocaisTrabalho,
  removeWorkplace,
  editWorkplace,
  editId,
  handleEditChange,
  inputValue,
  inputSelectValue,
  inputDataValue,
  setInputValue,
  setImputSelectValue,
}) => {
  return arrLocaisTrabalho.map((Workplace) => (
    <div className="lineRm">
      {editId === Workplace.id ? (
        <>
          <select
            id="camposLogin"
            onChange={(e) => setImputSelectValue(e.target.value)}
            value={inputSelectValue}
            placeholder="Título"
          >
            <option value="criminal">Direito Criminal</option>
            <option value="civil">Direito Civil</option>
            <option value="trabalhista">Direito Trabalhista</option>
            <option value="consumidor">Direito Do Consumidor</option>
            <option value="tributário">Direito Tributário</option>
            <option value="previdenciario">Direito Previdenciário</option>
          </select>{" "}
          <input
            id="camposLogin"
            type="localization"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
          <input
            type="data"
            value={inputDataValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
        </>
      ) : (
        <tr id="tabela">
          <td className="tb"> {Workplace.building}</td>
          <td className="tb">{Workplace.localization} </td>
          <td className="tb">{Workplace.data} </td>
        </tr>
      )}
      {editId === Workplace.id ? (
        <DoneIcon
          id="done"
          onClick={() => {
            editWorkplace(Workplace.id, inputValue, inputSelectValue);
          }}
        ></DoneIcon>
      ) : (
        <>
          <DeleteOutlineOutlinedIcon
            id="del"
            onClick={() => {
              removeWorkplace(Workplace.id);
            }}
          >
            {" "}
          </DeleteOutlineOutlinedIcon>
          <EditIcon
            id="edit"
            onClick={() =>
              handleEditChange(
                Workplace.id,
                Workplace.localization,
                Workplace.building
              )
            }
          >
            {" "}
          </EditIcon>
        </>
      )}
    </div>
  ));
};

export default Workplace;
