import React from "react";
import { nav1, nav2, nav3, nav5 } from "../redux/action/navAction";
import { useDispatch } from "react-redux";
import Header from "../components/header.js";

function LeftSide() {
  const administracao = "Administração";
  const administradores = "Administradores";
  const areas = "Áreas";
  const habilidades = "Habilidades";
  const dispatch = useDispatch();
  function fechar() {
    const fecha = document.getElementById("menu-toggle");
    fecha.checked = false;
  }

  return (
    <>
      <div>
        <nav class="navigation-menu">
          <br></br>
          <label
            class="hamburger-icon"
            aria-label="Open navigation menu"
            for="menu-toggle"
          >
            &#9776;
          </label>
          <Header></Header>

          <input type="checkbox" id="menu-toggle" />
          <ul class="main-navigation">
            <br></br>
            <li>
              <div>
                {" "}
                <button
                  id="btnMenu"
                  onClick={() => dispatch(nav1(administracao)) & fechar()}
                >
                  Qualificação
                </button>
              </div>
            </li>
            <li>
              <div>
                {" "}
                <button
                  id="btnMenu"
                  onClick={() => dispatch(nav2(administradores)) & fechar()}
                >
                  Demanda
                </button>
              </div>
            </li>
            <li>
              <div>
                {" "}
                <button
                  id="btnMenu"
                  onClick={() => dispatch(nav3(habilidades)) & fechar()}
                >
                  {" "}
                  Documentos{" "}
                </button>
              </div>
            </li>
            <li>
              <div>
                {" "}
                <button
                  id="btnMenu"
                  onClick={() => dispatch(nav5(areas)) & fechar()}
                >
                  {" "}
                  Processos
                </button>
              </div>
            </li>
          </ul>
        </nav>
      </div>

      <div id="backleft">
        <div id="px50"></div>
        <button id="menuback" onClick={() => dispatch(nav1(administracao))}>
          Dados Pessoais
        </button>
        <button id="menuback" onClick={() => dispatch(nav2(administradores))}>
          Demandas
        </button>
        <button
          id="menuback"
          onClick={() => dispatch(nav3(habilidades)) & window.scrollBy(0, 0)}
        >
          Documentos
        </button>

        <button
          id="menuback"
          onClick={() => dispatch(nav5(areas)) & window.scrollBy(0, 0)}
        >
          Meus Processos
        </button>
      </div>
    </>
  );
}

export default LeftSide;
