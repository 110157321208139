import React from 'react'

function Notfound(){

    return(
        <div className="notfound" >404
         <div className="error" >Erro 404: Página não encontrada.</div>
        </div>
       
    )
}


export default Notfound