

function Escritorio() {
  return (
    <div id="corpo">
      <div id="title1">O escritório</div>
      <div id="px15"></div>
      <div id="title2">CASTRO ADVOGADOS</div>
      <div id="px30"></div>

      <div id="p1">
        {" "}
        O escritório está localizado na rua João Batista Fernandes, n. 323 -
        Vila João Ribeiro, Araxá/MG e tem se destacado no mercado jurídico,
        oferecendo soluções modernas e inovadoras. Temos o objetivo de crescer
        de forma consistente, através de parcerias com clientes e profissionais
        de excelência, mantendo sempre o compromisso com a ética, o
        profissionalismo e a qualidade das soluções jurídicas que apresentamos.
      </div>

      <br></br>
      <div id="px30"></div>
      <div id="filtro">
        <div id="camadaFiltro"></div>
      </div>

      <div id="p2">
        Com o propósito de instituir um ambiente íntegro a seus clientes e
        colaboradores, o escritório Castro Advogados apresenta:
      </div>

      <div id="homecastro"></div>
      <div id="descr">
        <div id="card">
          <hr></hr>
          <div id="card_escrita">
            <strong> NOSSA VISÃO,</strong> Consolidar o escritório em âmbito
            regional, mantendo as relações com nossos cliente.
          </div>
        </div>

        <div id="card">
          <div id="card_escrita">
            <strong> NOSSA MISSÃO,</strong> Prover soluções jurídicas com
            agilidade e alta performance, visando alcançar os objetivos de
            nossos clientes de forma inteligente.
          </div>
        </div>

        <div id="card">
          <div id="card_escrita">
            <strong> NOSSOS VALORES,</strong> Destemor, Honestidade, Eficiência
            jurídica, Agilidade e Responsabilidade.
            <hr></hr>
          </div>
         
        </div>
      </div>

      <div id="px30"></div>
      <div id="endereco2"></div>
    </div>
  );
}

export default Escritorio;
