import Upimg from "../components/Upimg";

function Skills() {
  return (
    <div id="data">
      <Upimg></Upimg>
    </div>
  );
}

export default Skills;
