import React from "react";
import { menu1, menu2, menu3, menu4 } from "../../redux/action/navActionPublic";
import { useDispatch } from "react-redux";
import "../../styles/css/desk.css";
import "../../styles/css/tab.css";
import "../../styles/css/mob.css";
import "normalize.css";

function MenuPublic() {
  const dispatch = useDispatch();
  const home = "HOME";
  const escritorio = "ESCRITORIO";
  const advogados = "ADVOGADOS";
  const cliente = "CLIENTE";

  function fechar() {
    const fecha = document.getElementById("menu-toggle");
    fecha.checked = false;
  }

  return (
    <div>
      <nav class="navigation-menu">
        <br></br>
        <label
          class="hamburger-icon"
          aria-label="Open navigation menu"
          for="menu-toggle"
        >
          &#9776;
        </label>
        <input type="checkbox" id="menu-toggle" />
        <ul class="main-navigation">
          <li>
            <div>
              {" "}
              <button
                id="btnMenu"
                onClick={() => dispatch(menu1(home)) & fechar()}
              >
                Home
              </button>
            </div>
          </li>
          <li>
            <div>
              {" "}
              <button
                id="btnMenu"
                onClick={() => dispatch(menu2(escritorio)) & fechar()}
              >
                {" "}
                Escritório{" "}
              </button>
            </div>
          </li>
          <li>
            <div>
              {" "}
              <button
                id="btnMenu"
                onClick={() => dispatch(menu3(advogados)) & fechar()}
              >
                {" "}
                Advogados{" "}
              </button>
            </div>
          </li>
          <li>
            <div>
              {" "}
              <button
                id="btnMenu"
                onClick={() => dispatch(menu4(cliente)) & fechar()}
              >
                {" "}
                Contato
              </button>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default MenuPublic;
