import React, { Component } from "react";
import Resizer from "react-image-file-resizer";
import firebase from "../../src/components/firebase/firebase";

class Upimg extends Component {
  constructor(props) {
    super(props);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.state = {
      newImage: "",
    };
  }

  fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          800,
          600,
          "JPEG",
          100,
          0,
          (profileImg) => {
           
          this.setState({ newImage: profileImg });
           async function loading() {

            if(profileImg.length === 0){
              return;
            } else{
      
      
            try {
              await firebase.dataBaseImagens(profileImg);
      
             
            } catch (e) {
              console.log(e.message);
            }
          }
          }

          loading();


          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  render() {
    return (
      <div className="login">
         3º passo - Descrição do caso e Juntada de Documentos
        <input id="file" type="file" onChange={this.fileChangedHandler} />
        <img id="imagembd" src={this.state.newImage} alt="" />
      </div>
    );
  }
}

export default Upimg;