import React, { useState } from "react";
//import AddIcon from "@material-ui/icons/Add";
//import { useDispatch, useSelector } from "react-redux";
import "react-redux";
import "redux";
import { makeStyles } from "@material-ui/core/styles";

makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

//import firebase from "../../src/components/firebase/firebase"
function WorkplaceForm(props) {
  // const stateAdd = useSelector((state) => state.LogReduceradd);//
  // const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [select, setSelect] = useState("");
  const [nome, setInputDate] = useState("");

  // const [log, setLog] = useState("");
  // const [logs, setLogs] = useState(stateAdd);

  const handleChange = (e) => {
    setInput(e.target.value);
    //setLog(e.target.value);
  };
  const handleChangeSelect = (e) => {
    setSelect(e.target.value);
  };

  const handleChangeDate = (e) => {
    setInputDate(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (select.length === 0) {
      alert("Verifique os campos");

      return;
    }

    //setLogs([].concat(logs, log));*//

    props.onSubmit({
      id: Math.floor(Math.random() * 10000),
      building: select,
      localization: input,
      data: nome,
    });

    setInput("");
  };

  // dispatch(addEvents(logs));//

  return (
    <div className="data">
      <hr></hr>
      2º passo - Área de Direito
      <form onSubmit={handleSubmit}>
        <select onChange={handleChangeSelect} value={select} id="selectback">
          <option value="criminal">Direito Criminal</option>
          <option value="civil">Direito Civil</option>
          <option value="trabalhista">Direito Trabalhista</option>
          <option value="consumidor">Direito Do Consumidor</option>
          <option value="tributário">Direito Tributário</option>
          <option value="previdenciario">Direito Previdenciário</option>
        </select>{" "}
        <input
          id="inputback"
          type="text"
          placeholder="usuário"
          value={input}
          onChange={handleChange}
          name="localization"
        />{" "}
        <input
          type="date"
          id="inputback"
          onChange={handleChangeDate}
          value={nome}
        />{" "}
        <button onClick={handleSubmit} id="btn_init_soma">
          +
        </button>
        <br></br>
        <div></div>
        <hr></hr>
        
      </form>
    </div>
  );
}

export default WorkplaceForm;
