import LogoS from "../../styles/image/adv/logoSVG.svg";
import Rodape from "./RodaPe"
function Castro() {
  return (
    <div id ="corpo">
      <div id="title1">Excelência</div>
      <div id="px15"></div>
      <div id="title2">EM ADVOCACIA</div>
     
      <div id="logoback">
        <img id="logotablet" alt="logo" src={LogoS}></img>
        <div id="rodape">
          Atendimento ágil, nossos serviços são prestados por
          Advogados dedicados a solução do seu caso.
        </div>
        <Rodape></Rodape>
      
      </div>
     
     
    </div>
    
  );
}

export default Castro;
