export default  function LogDelReducer(state = [], action) {
    const AP = action.payload;
  
    switch (action.type) {
      case "LIST":
        return AP[0] 
  
      default:
        return state;
    }
  }
  
  
  