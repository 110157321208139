import React from "react";
import LefetSide from '../../components/LeftSide'
import Container from '../../components/container'
import ContainerPublic from "../pages/ContainerPublic"



function Start() {
  return (
    <>
    
      <div className="leftSide">
        <LefetSide />
      </div>
      <div className="container">
        <Container />
        <ContainerPublic></ContainerPublic>
      </div>
    </>
  );
}

export default Start