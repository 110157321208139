import React, { useState } from "react";
import firebase from "../components/firebase/firebase";
import { nav2 } from "../redux/action/navAction";
import { useDispatch } from "react-redux";

export default function Administration() {
  const dispatch = useDispatch();
  const administradores = "Administradores";

  //const current = new Date();
  // const date = `${current.getDate()}/${
  //  current.getMonth() + 1
  //}/${current.getFullYear()}`;

  //const currentH = new Date();
  //const time = `${currentH.getHours()}:${currentH.getMinutes()}:${currentH.getSeconds()}`;

  //  const ticket = localStorage.getItem(STORAGE_KEY) + date + time;
  const [author, setAutor] = useState("");
  const [description, setDescription] = useState("");
  const [count, setCount] = useState(0);

  async function dataBase() {
    try {
      const data = await firebase.dataBase(author, description);
      if (data) {
      } else {
      }
    } catch (error) {
      document.querySelector(".e").innerHTML = error.message;
      console.log(error.message);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (author.length === 0) {
      return alert("A confirmação do Nome é obrigatória");
    } else {
      dataBase();
      setAutor("");
      setDescription("");
    }
  }
  return (
    <div className="data">
      <hr></hr>
      <div>
        <div className="">
        1º passo - Nome e contato
          <div className="campoBT">
            <form onSubmit={handleSubmit}>
              <div className="cadastroDeUsuario">
                <br></br>
                <input
                  placeholder="Nome:"
                  id="inputback"
                  label=""
                  value={author}
                  onChange={(e) => setAutor(e.target.value)}
                ></input>{" "}
                <br></br>
                <input
                  placeholder="Telefone:"
                  id="inputback"
                  className=""
                  type=""
                  label=""
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></input>
                <br></br>
                <div className=""></div>
                <br></br>
                <br></br>
                <button
                  id="btnback"
                  type="submit"
                  onClick={() =>
                    setCount(count + 1) & dispatch(nav2(administradores))
                  }
                >
                  {" "}
                  CADASTRAR{" "}
                </button>
                <br></br>
                <div className=""></div>
                <div className="e"></div>
                <div className="sucesso"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
