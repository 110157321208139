import React, { useState } from "react";
import firebase from "../../components/firebase/firebase";
import { history } from "../pages/history";
import seta from "../../styles/icons/seta.png"
import {menu7} from "../../redux/action/navActionPublic"
import {useDispatch} from "react-redux"
import logo from "../../styles/image/adv/logobranco.png"


const loginState = "LOGIN"


export default function Register() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirme, setPasswordConfirme] = useState("");
  const [name] = useState("");

  async function register() {
    if (password !== passwordConfirme) {
      alert("senhas não são iguais");
    }

    try {
      const data = await firebase.register(name, email, password);

      history.push("/escritorio");
      window.location.href = "/escritorio";
      alert("usuário cadastrado com seucesso. Entre com usuário e senha.");

      if (data) {
      }
    } catch (error) {
      if (
        error.message ===
        "The email address is already in use by another account."
      ) {
        const emailCadastrado = "Email já Cadastrado";
        document.querySelector(".es").innerHTML = emailCadastrado;
      }

      document.querySelector(".e").innerHTML = error.message;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    register();
  }

  return (
    <div id="areacliente">
      <div id="fields">
      <img id="logoLogin" alt="login"src={logo}></img>
      <div id="px30"></div>

        <form onSubmit={handleSubmit}>
          <input
            id="camposLogin"
            type="email"
            placeholder="e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>{" "}
          <br></br>
          <br></br>
          <input
            id="camposLogin"
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <br></br>
          <br></br>
          <input
            id="camposLogin"
            type="password"
            placeholder="Confirmar Senha"
            value={passwordConfirme}
            onChange={(e) => setPasswordConfirme(e.target.value)}
          ></input>
          <br></br>
          <br></br>
         
          <div></div>
          <br></br>
          <br></br>
          <button id="btns" type="submit">
            Criar conta
          </button>
          <div id= "voltar">
              <img id="seta" alt="seta" src={seta} onClick={()=>dispatch(menu7(loginState))}></img>
            </div>
          <div className="e"></div>
          <div className="es"></div>
        </form>
      </div>
    </div>
  );
}
