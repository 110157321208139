import React from "react";

function Responsible() {
  return <div className="data">

    
  </div>;
}

export default Responsible;
