import React from "react";

import { useSelector } from "react-redux";

function ContainerPublic() {
  const navStatusP = useSelector((state) => state.menuPublicReducer);

  if (navStatusP === "Apresentacao") {
    return <h1>Apresentacao</h1>;
  }

  if (navStatusP === "Profissionais") {
    return <h1>Atendimento</h1>;
  }

  if (navStatusP === "Atendimento") {
    return <h1>Atendimento</h1>;
  }

  return <></>;
}

export default ContainerPublic;
