import React from "react";
import Menu from "../../components/publico/MenuPublic.js";
import ContainerPublic from "../../components/publico/ContainerPublic";

export default function escritorio() {
  return (
    <div id="corpo">
      <div id="camada">
        <Menu></Menu>
        <div>
          <div id="containerPublico">
            <ContainerPublic></ContainerPublic>

          </div>
        </div>
      </div>
    </div>
  );
}
