import React from "react";

import Administration from "../view/Administration";
import Surface from "../view/Surface";
import WorkplaceList from "../view/WorkplaceList";
import Skills from "../view/Skills";
import Log from "../view/Log";
import Responsible from "../view/Responsible";

import { useSelector } from "react-redux";

function Container() {
  const navStatus = useSelector((state) => state.navReducer);

  if (navStatus === "Administração") {
    return (
      <div className="adm">
        <Administration></Administration>
      </div>
    );
  }

  if (navStatus === "Administradores") {
    return (
      <div id="adms">
        <WorkplaceList></WorkplaceList>
      </div>
    );
  }

  if (navStatus === "docs") {
    return (
      <div id="cont">
        {" "}
        <Surface></Surface>
      </div>
    );
  }

  if (navStatus === "Local de Trabalho") {
    return <div id="cont"> </div>;
  }

  if (navStatus === "Habilidades") {
    return (
      <div id="process">
        {" "}
        <Skills></Skills>
      </div>
    );
  }

  if (navStatus === "Log") {
    return (
      <div id="cont">
        {" "}
        {navStatus} <Log></Log>
      </div>
    );
  }

  if (navStatus === "Áreas") {
    return (
      <div>
        {" "}
        {navStatus} <Responsible></Responsible>
      </div>
    );
  }

  return <WorkplaceList></WorkplaceList>;
}

export default Container;
