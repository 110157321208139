export default function NavReducerPublic(state = "HOME", action) {
  const AP = action.payload;

  switch (action.type) {
    case "MENU1":
      return AP[0];

    case "MENU2":
      return AP[0];

    case "MENU3":
      return AP[0];

    case "MENU4":
      return AP[0];

    case "MENU5":
      return AP[0];

    case "MENU6":
      return AP[0];
    case "MENU7":
      return AP[0];

    default:
      return state;
  }
}
