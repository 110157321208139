import React from "react";




function Log() {
  

  return (
    <>
     
    </>
  );
}

export default Log;
