export function menu1(home) {
  return {
    type: "MENU1",
    payload: [home],
  };
}

export function menu7(loginState) {
  return {
    type: "MENU7",
    payload: [loginState],
  };
}

export function menu2(escritorio) {
  return {
    type: "MENU2",
    payload: [escritorio],
  };
}

export function menu3(advogados) {
  return {
    type: "MENU3",
    payload: [advogados],
  };
}

export function menu5(register) {
  return {
    type: "MENU5",
    payload: [register],
  };
}

export function menu6(forgot) {
  return {
    type: "MENU6",
    payload: [forgot],
  };
}

export function menu4(cliente) {
  return {
    type: "MENU4",
    payload: [cliente],
  };
 
}
