import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import Start from "../view/pages/start";
import Notfound from "../view/pages/notfound";
import Criminal from "../view/pages/ads/Criminal.js"
import PrivateRoute from "./PrivateRoute";
import escritorio from "../view/pages/escritorio";
import { history } from "../view/pages/history";

export default function Rotas() {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute component={Start} exact path="/" />
        <Route component={escritorio} exact path="/escritorio" />
        <Route component={Criminal} exact path="/criminal" />

        <Route component={Notfound} />
      </Switch>
    </Router>
  );
}
