import Video from "../../../styles/image/adv/videoplayback.mp4";
import "../../../styles/css/desk.css";
import Logo from "../../../styles/image/adv/logoSVG.svg";
import Logowp from "../../../styles/image/adv/logowp.png";
import { menu1 } from "../../../redux/action/navActionPublic";
import { history } from "../../pages/history";
import { useDispatch } from "react-redux";

export default function Criminal() {
  const dispatch = useDispatch();
  const homeState = "HOME";
  return (
    <div id="background-video">
      <video id="vd" autoPlay loop muted>
        <source src={Video} type="video/mp4" />
      </video>
      <div>
        <div id="filtrocriminal">
          <div id="titlecriminal">
            <div>
              <img id="logocriminal" alt="logo" src={Logo}></img>
            </div>
            ESPECIALISTA EM DIREITO CRIMINAL
            <div id="titlecriminal2">
              Precisa de um advogado criminalista em Araxá e região?
            </div>
          </div>
          <button
            id="btnw"
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=5534988211143&text=Castro%20Advogados%20-%20Atendimento%20presencial%20à%20rua%20João%20Batista%20Fernandes,%20n.%20323%20-%20Vila%20João%20Ribeiro,%20Araxá/MG"
              );
            }}
          >
            {" "}
            <img id="logowp" alt="wp" src={Logowp}></img> Fale com o
            especialista
          </button>
          <br></br>
          <button
            id="btne"
            onClick={() => {
              history.push("/");
              window.location.href = "/";
            }}
          >
            Conheça o Escritório
          </button>
        </div>
      </div>
    </div>
  );
}
