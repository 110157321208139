import React, { useState } from "react";
import firebase from "../../components/firebase/firebase";
import seta from "../../styles/icons/seta.png";
import { menu7 } from "../../redux/action/navActionPublic";
import { useDispatch } from "react-redux";
import logo from "../../styles/image/adv/logobranco.png";

export default function Forgot() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const loginState = "LOGIN";

  async function forgot() {
    try {
      await firebase.forgot(email);

      if (email.length === 0) {
        return;
      }

      const emailenviado =
        "Link enviado para o email, siga as instruções e altere a senha.";
      document.querySelector(".reset").innerHTML = emailenviado;
    } catch (error) {
      document.querySelector(".e").innerHTML = error.message;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    forgot();
  }

  return (
    <div id="areacliente">
      <img id="logoLogin" alt="logo" src={logo}></img>
      <div id="px30"></div>

      <form onSubmit={handleSubmit}>
        <input
          id="camposLogin"
          type="email"
          label="EMAIL"
          placeholder="Entre com Seu email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>{" "}
        <br></br>
        <div className="util"></div>
        <br></br>
        <br></br>
        <button type="submit" id="btns">
          {" "}
          Recuperar Senha{" "}
        </button>
        <div id="voltar">
          <img
          alt="volta"
            id="seta"
            src={seta}
            onClick={() => dispatch(menu7(loginState))}
          ></img>
        </div>
        <br></br>
        <div className="e"></div>
        <div className="reset"></div>
      </form>
    </div>
  );
}
