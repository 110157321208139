import React from "react";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { STORAGE_KEY } from "../components/storage";

function Header() {
  const email = localStorage.getItem(STORAGE_KEY);
  return (
    <div className="header">
      <div id="profile">
        <div className="userProfile">
          <div id="controlUser">
            <div id="nomemail">{email}</div>{" "}
            <HomeOutlinedIcon fontSize="medium" id="home" />{" "}
            <PermIdentityOutlinedIcon fontSize="medium" id="home" />{" "}
            <ExitToAppIcon
              fontSize="medium"
              id="home"
              onClick={() => {
                localStorage.removeItem(
                  STORAGE_KEY,
                  (window.location.href = "/escritorio")
                );
              }}
            />{" "}
            <br></br>
            <div className="user">
              <br></br> <br></br>{" "}
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default Header;
