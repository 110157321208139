import React, { useState } from "react";
import firebase from "../../components/firebase/firebase";
import { history } from "../pages/history";
import { STORAGE_KEY } from "../../components/storage";
import { useDispatch } from "react-redux";
import { menu6 } from "../../redux/action/navActionPublic";
import seta from "../../styles/icons/seta.png";

const forgot = "FORGOT";


export default function Login() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function login() {
    try {
      const data = await firebase.login(email, password);
      if (data) {
        localStorage.setItem(STORAGE_KEY, data.user.email);

        history.push("/");
        window.location.href = "/";
      } else {
        alert("não encontrado");
      }
    } catch (error) {
      document.querySelector(".e").innerHTML = error.message;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    login();
  }

  return (
    <>
      <div id="camp">
        <strong> CONTATO</strong>
        <br></br>
        juliano@castroadvs.com.br | 34-98839735<br></br>
        marcela@castroadvs.com.br | 34-9329-3231<br></br>
        <br></br>
        <strong>LOCALIZAÇÃO</strong>
        <br></br>
        Rua João Batista Fernandes, n. 323 - Vila João Ribeiro, Araxá/MG.
      </div>
      <div id="areacliente">
        <div id="fields">
          <button
            id="btnw"
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=5534988211143&text=Castro%20Advogados%20-%20Atendimento%20presencial%20à%20rua%20João%20Batista%20Fernandes,%20n.%20323%20-%20Vila%20João%20Ribeiro,%20Araxá/MG"
              )
            }}
          >
            {" "}
            WhatsApp
          </button>
          <br></br>

          <form onSubmit={handleSubmit}>
            <br></br>
            <input
              id="camposLogin"
              type="email"
              label=""
              placeholder="e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>{" "}
            <br></br>
            <br></br>
            <input
              id="camposLogin"
              type="password"
              label=""
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <br></br>
            <br></br>
            <button type="submit" id="btns">
              {" "}
              Entrar{" "}
            </button>
            <br></br>
            <button
              id="btns"
              onClick={() => {
                dispatch(menu6(forgot));
              }}
            >
              Esqueceu a senha?
            </button>{" "}
            <br></br>
            <div id="voltar">
              <img id="seta" alt="seta" src={seta}></img>
            </div>
            <br></br>
            <div className="e"></div>
            <div className="sucesso"></div>
          </form>
        </div>
      </div>
    </>
  );
}
