import Rodape from "./RodaPe"

function Advogados() {
  return (
    <div id="avat">
      <div id="linha">
        <div id="icone">
         <div id="px30"></div>
        </div>{" "}
        <div> <strong> Dr. JULLIANO CASTRO</strong></div>
      </div>
      <div id="linha">
        <div id="icone">
        </div>{" "}
        <div> Número de Ordem: 200195</div>
      </div>

      <div id="linha">
        <div id="icone">
        </div>{" "}
        <div>Subseção: 13 - UBERLÂNDIA</div>
      </div>

      <div id="linha">
        <div id="icone">
        </div>{" "}
        <div> julliano@castroadvs.com.br</div>
      </div>
      <div id="linha">
        <div id="icone">
        </div>{" "}
        <div> Cel: 34-98821-1143</div>
      </div>
      <div id="px15"></div>

      <div id="linha">
        <div id="icone">
        </div>{" "}
        <div id ="px30"></div>
        <div><strong>Dra. MARCELA DE CASTRO</strong> </div>
      </div>
      <div id="linha">
        <div id="icone">
        </div>{" "}
        <div> Número de Ordem: 194154</div>
      </div>

      <div id="linha">
        <div id="icone">
        </div>{" "}
        <div>Subseção: 33 - ARAXÁ</div>
      </div>

      <div id="linha">
        <div id="icone">
        </div>{" "}
        <div> marcela@castroadvs.com.br</div>
      </div>
      <div id="linha">
        <div id="icone">
        </div>{" "}
        <div> Cel: 34-99329-3231</div>
      </div>
     <Rodape></Rodape>
    </div>
  );
}

export default Advogados;
