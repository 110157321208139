//import { ArrowBackIosTwoTone } from "@material-ui/icons";
import app from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import { useHistory } from "react-router-dom";
//import { dataBaseGet } from "../../components/firebase/firebase";
import { STORAGE_KEY } from "../../components/storage";

const config = {
  apiKey: "AIzaSyDw5XAuywJr_99S2QjEtYDhtFTwCNAnmTs",
  authDomain: "controlelocaltrabalho.firebaseapp.com",
  databaseURL: "https://login-4de2e.firebaseio.com",
  projectId: "controlelocaltrabalho",
  storageBucket: "controlelocaltrabalho.appspot.com",
  messagingSenderId: "71128841841",
  appId: "1:71128841841:web:eef417438fd9b8a32ee5d0",
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.firestore = app.firestore();
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }

  async register(name, email, password) {
    
    await this.auth.createUserWithEmailAndPassword(email, password);
    return this.auth.currentUser.updateProfile({
     
    });
   

  }

  forgot(email) {
    return this.auth
      .sendPasswordResetEmail(email)
      .then(function () {
        alert("Link para alterar a senha foi encaminhado para o e-mail")
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  async dataBase(author, description) {

    this.firestore
      .collection("cadastro")
      .doc()
      .set({
        author: author, //
        description: description, //
      })
      .then(() => {
        alert("Obrigado! Contamos com sua Presença. 😀")

      })
      .catch((error) => {
        console.log(error);
      });
  }

  async dataBaseApostas(newarrLocaisTrabalho) {
    const emailFire = localStorage.getItem(STORAGE_KEY);

    this.firestore
      .collection("Apostas")
      .doc(emailFire)
      .set({
        newarrLocaisTrabalho,
      })
      .then(() => {
        alert("cadastrado ")
      })
      .catch((error) => {
        console.log(error);
      });
  }


  async dataBaseImagens(profileImg) {

    this.firestore
      .collection("fotos")
      .doc()
      .set({
        profileImg
      })
      .then(() => {
        alert("Arquivo enviado ")
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async dataBaseGet() {
    this.firestore
      .collection("cadastro")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
        });
      });
  }

  isInitialized() {
    return new Promise((email) => {
      app.auth().onAuthStateChanged((email) => {
        let islloged = useHistory();
        if (email !== null) {
          console.log(islloged);
        } else {
        }
      });
    });
  }

  getCurrentUsername() {
    console.log(this.auth.currentUser);
    return this.auth.currentUser;
  }
}
export default new Firebase();
