import Rotas from "./components/rotas";



function App() {
  return (
    <div id="camada">
     <Rotas></Rotas>
     
    </div>
  );
}

export default App;
